import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IAccountPersonalState } from "@interfaces/store/IAccountPersonalState"

const accountPersonalState: IAccountPersonalState = {
  register: {
    loading: false,
    data: {},
    error: false,
  },
}

const accountPersonalSlice = createSlice({
  name: "AccountPersonal",
  initialState: accountPersonalState,
  reducers: {
    setRegisterAccountLoading: (state) => {
      state.register.loading = true
    },
    setRegisterAccountComplete: (state, action: PayloadAction<any>) => {
      state.register.data = action.payload
      state.register.loading = false
    },
    setRegisterAccountError: (state) => {
      state.register.error = true
      state.register.loading = false
    },
  },
})

export default accountPersonalSlice
