// Packages
import React from "react"
import { Navigate } from "react-router-dom"

// Utils
import { ICustomRouteProps } from "@interfaces/components/ICustomRouteProps"
import { StorageService } from "@services/core/storage.service"

const ProtectedRoute: React.FC<ICustomRouteProps> = (props) => {
  const isAuthenticated = StorageService.instance().item("security_context") !== null
  if (!isAuthenticated) return <Navigate to="/login" replace />
  return props.children
}

export default ProtectedRoute
