export const HEADER_ACCEPT: string = "application/json"
export const HEADER_CONTENT_TYPE: string = "application/json; charset=utf-8;"
export const CALLBACK_URL: string = "https://workspaces-dev.aia.rocks/login"

export const ROUTES: Readonly<Record<string, string>> = {
  ROOT: "/",
  LOGIN: "/login",
  SIGN_UP_ACCOUNT_USER: "/sign-up/account",
  SIGN_UP_ACCOUNT_OWNER_ONE: "/sign-up/account/owner/step-1",
  SIGN_UP_ACCOUNT_OWNER_TWO: "/sign-up/account/owner/step-2",
  SIGN_UP_ACCOUNT_OWNER_VERIFY_EMAIL: "/sign-up/verify/email",
  CREATE_PASSWORD_OWNER: "/account/owner/password",
  WORKSPACES: "/workspaces",
  WORKSPACE_USERS: "/workspace/:id/users",
  WORKSPACE_ROLES: "/workspace/:id/roles",
  USER_EDIT: "/workspace/:id/users/:user_id/edit",
  BUSINESS: "/workspace/:id/business",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  NOT_FOUND: "*",
}
