import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IWorkspaceUsersState } from "@interfaces/store/IWorkspaceUsersState"

const initialState: IWorkspaceUsersState = {
  data: {
    users: [],
    paginate: {
      currentPage: 1,
      total: 1,
      perPage: 10,
      path: "",
      firstPageUrl: "",
      lastPageUrl: "",
      nextPageUrl: "",
      prevPageUrl: "",
    },
    user: null,
    user_security: null,
    permissions: [],
  },
  loading: false,
  error: false,
}

const workspaceUsersSlice = createSlice({
  name: "WorkspaceUsers",
  initialState,
  reducers: {
    setFetchUsersLoading: (state) => {
      state.loading = true
    },
    setFetchUsersComplete: (state, { payload }: PayloadAction<any>) => {
      const {
        data: {
          data: users,
          current_page: currentPage,
          total,
          path,
          per_page: perPage,
          first_page_url: firstPageUrl,
          last_page_url: lastPageUrl,
          next_page_url: nextPageUrl,
          prev_page_url: prevPageUrl,
        },
      } = payload
      state.data.users = users
      state.data.paginate = {
        currentPage: parseInt(currentPage),
        total: parseInt(total),
        perPage: parseInt(perPage),
        path,
        firstPageUrl,
        lastPageUrl,
        nextPageUrl,
        prevPageUrl,
      }
      state.loading = false
      state.error = false
    },
    setFetchUsersError: (state) => {
      state.loading = false
      state.error = true
    },

    setInviteUserLoading: (state) => {
      state.loading = true
    },
    setInviteUserComplete: (state) => {
      state.loading = false
    },
    setInviteUserError: (state) => {
      state.loading = false
      state.error = true
    },

    setCreateDepartmentLoading: (state) => {
      state.loading = true
    },
    setCreateDepartmentComplete: (state) => {
      state.loading = false
      state.error = false
    },
    setCreateDepartmentError: (state) => {
      state.loading = false
      state.error = true
    },

    editUserLoading: (state) => {
      state.loading = true
    },
    editUserComplete: (state) => {
      state.loading = false
    },
    editUserError: (state) => {
      state.loading = false
      state.error = true
    },

    setUser: (state, { payload }: PayloadAction<any>) => {
      state.data.user = payload
    },

    setSecurity: (state, { payload }: PayloadAction<any>) => {
      state.data.user_security = payload
    },
  },
})

export default workspaceUsersSlice
