import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ISecurityState } from "@interfaces/store/ISecurityState"

const rootInitialState: ISecurityState = {
  login: {
    data: {
      authenticated: false,
    },
    loading: false,
    redirectToPassword: false,
    error: false,
  },
  register: {
    data: {},
    loading: false,
    error: false,
  },
  forgotPassword: {
    loading: false,
    data: {},
    error: false,
  },
  resetPassword: {
    loading: false,
    data: {},
    error: false,
  },
}

const securitySlice = createSlice({
  name: "Security",
  initialState: rootInitialState,
  reducers: {
    // LOGIN
    setLoginLoading: (state) => {
      state.login.loading = true
    },
    setLoginComplete: (state, action: PayloadAction<boolean>) => {
      state.login.data.authenticated = action.payload
      state.login.loading = false
    },
    setLoginFailed: (state) => {
      state.login.data.authenticated = false
      state.login.loading = false
      state.login.error = true
    },

    setForgotPasswordLoading: (state) => {
      state.forgotPassword.loading = true
      state.forgotPassword.error = false
      state.forgotPassword.data = {}
    },
    setForgotPasswordCompleted: (state) => {
      state.forgotPassword.loading = false
    },
    setForgotPasswordError: (state, { payload }: PayloadAction<any>) => {
      state.forgotPassword.loading = false
      state.forgotPassword.error = true
      state.forgotPassword.data = payload
    },

    setRedirectToPassword: (state, { payload }: PayloadAction<boolean>) => {
      state.login.redirectToPassword = payload
    },

    // LOGOUT
    setLogoutComplete: (state) => {
      state.login.data.authenticated = false
    },
  },
})

export default securitySlice
