// Packages
import React, { lazy, Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import * as CONSTANTS from "@utils/constants"
import "./App.css"

// Layouts & Components
import DefaultLayout from "@layouts/Default/Default"
import GuestLayout from "@layouts/Guest/Guest"
import ProtectedRoute from "@components/route/ProtectedRoute/ProtectedRoute"

const Loader = lazy(() => import("@components/common/Loader/Loader"))
const HomePage = lazy(() => import("@pages/Home/Home"))
const LoginPage = lazy(() => import("@pages/Login/Login"))
const SignUpUserPage = lazy(() => import("@pages/SignUpUser/SignUpUser"))
const SignUpOwnerStepOnePage = lazy(() => import("@pages/SignUpOwner/SignUpStepOne"))
const SignUpOwnerStepTwoPage = lazy(() => import("@pages/SignUpOwner/SignUpStepTwo"))
const VerifyEmailPage = lazy(() => import("@pages/VerifyEmail/VerifyEmail"))
const CreatePasswordOwnerPage = lazy(() => import("@pages/CreatePasswordOwner/CreatePasswordOwner"))
const WorkspacesPage = lazy(() => import("@pages/Workspaces/Workspaces"))
const WorkspaceUsersPage = lazy(() => import("@pages/WorkspaceUsers/WorkspaceUsers"))
const WorkspaceRolesPage = lazy(() => import("@pages/Role/Role"))
const EditUserPage = lazy(() => import("@pages/EditUser/EditUser"))
const BusinessPage = lazy(() => import("@pages/Business/Business"))
const NotFoundPage = lazy(() => import("@pages/NotFound/NotFound"))

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route
              path={CONSTANTS.ROUTES.ROOT}
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={CONSTANTS.ROUTES.WORKSPACES}
              element={
                <ProtectedRoute>
                  <WorkspacesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={CONSTANTS.ROUTES.WORKSPACE_USERS}
              element={
                <ProtectedRoute>
                  <WorkspaceUsersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={CONSTANTS.ROUTES.WORKSPACE_ROLES}
              element={
                <ProtectedRoute>
                  <WorkspaceRolesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={CONSTANTS.ROUTES.USER_EDIT}
              element={
                <ProtectedRoute>
                  <EditUserPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={CONSTANTS.ROUTES.BUSINESS}
              element={
                <ProtectedRoute>
                  <BusinessPage />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route element={<GuestLayout />}>
            <Route path={CONSTANTS.ROUTES.LOGIN} element={<LoginPage />} />
            <Route path={CONSTANTS.ROUTES.SIGN_UP_ACCOUNT_USER} element={<SignUpUserPage />} />
            <Route
              path={CONSTANTS.ROUTES.SIGN_UP_ACCOUNT_OWNER_ONE}
              element={<SignUpOwnerStepOnePage />}
            />
            <Route
              path={CONSTANTS.ROUTES.SIGN_UP_ACCOUNT_OWNER_TWO}
              element={<SignUpOwnerStepTwoPage />}
            />
            <Route
              path={CONSTANTS.ROUTES.SIGN_UP_ACCOUNT_OWNER_VERIFY_EMAIL}
              element={<VerifyEmailPage />}
            />
            <Route
              path={CONSTANTS.ROUTES.CREATE_PASSWORD_OWNER}
              element={<CreatePasswordOwnerPage />}
            />
          </Route>
          <Route path={CONSTANTS.ROUTES.NOT_FOUND} element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </div>
  )
}

export default App
