import { IStorageItem } from "@interfaces/services/IStorageItem"
import { IStorageService } from "@interfaces/services/IStorageService"

export class StorageService implements IStorageService {
  localStorageSupported: boolean
  private static _instance: StorageService

  private constructor() {
    this.localStorageSupported =
      typeof window["localStorage"] != "undefined" && window["localStorage"] != null
  }

  public static instance(): StorageService {
    if (!StorageService._instance) {
      StorageService._instance = new StorageService()
    }
    return StorageService._instance
  }

  clear(): void {
    if (this.localStorageSupported) {
      localStorage.clear()
    }
  }

  remove(key: string): boolean {
    if (this.localStorageSupported) {
      localStorage.removeItem(key)
      return true
    }
    return false
  }

  save(data: IStorageItem): void {
    if (this.localStorageSupported) {
      localStorage.setItem(data.key, data.value)
    }
  }

  item(key: string): string | null {
    if (this.localStorageSupported) {
      return localStorage.getItem(key)
    }
    return null
  }
}
