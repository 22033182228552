import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IAccountsState } from "@interfaces/store/IAccountsState"

const accountsState: IAccountsState = {
  data: [],
  security: {},
  loading: false,
  error: false,
}

const accountsSlice = createSlice({
  name: "Accounts",
  initialState: accountsState,
  reducers: {
    setFetchUserAccountsLoading: (state) => {
      state.loading = true
    },
    setFetchUserAccountsCompleted: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload
      state.loading = false
    },
    setFetchUserAccountsError: (state) => {
      state.loading = false
      state.error = true
    },

    setFetchPermissionsLoading: (state) => {
      state.loading = true
    },
    setFetchPermissionsComplete: (state, { payload }: PayloadAction<any>) => {
      state.loading = false
      state.security = payload
    },
    setFetchPermissionsError: (state) => {
      state.loading = false
      state.error = true
    },
  },
})

export default accountsSlice
