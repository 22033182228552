import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IRolesState } from "@interfaces/store/IRolesState"

const initialState: IRolesState = {
  data: {
    paginate: {
      currentPage: 1,
      total: 1,
      perPage: 10,
      path: "",
      firstPageUrl: "",
      lastPageUrl: "",
      nextPageUrl: "",
      prevPageUrl: "",
    },
    roles: [],
    permissions: [],
  },
  loading: false,
  error: false,
  errorMessage: {},
}

const rolesSlice = createSlice({
  name: "WorkspaceRoles",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true
    },
    setComplete: (state) => {
      state.loading = false
    },
    setError: (state, { payload }) => {
      state.loading = false
      state.error = true
      state.errorMessage = payload
    },
    setFetchDataComplete: (state, { payload }: PayloadAction<any>) => {
      const {
        roles: {
          data: roles,
          current_page: currentPage,
          total,
          path,
          per_page: perPage,
          first_page_url: firstPageUrl,
          last_page_url: lastPageUrl,
          next_page_url: nextPageUrl,
          prev_page_url: prevPageUrl,
        },
      } = payload

      state.data.roles = roles
      state.data.permissions = payload.permissions

      state.data.paginate = {
        currentPage: parseInt(currentPage),
        total: parseInt(total),
        perPage: parseInt(perPage),
        path,
        firstPageUrl,
        lastPageUrl,
        nextPageUrl,
        prevPageUrl,
      }
      state.loading = false
      state.error = false
    },
  },
})

export default rolesSlice
