import { configureStore } from "@reduxjs/toolkit"
import securitySlice from "@store/security/security.slice"
import workspaceSlice from "@store/workspace/workspace.slice"
import workspaceUsersSlice from "@store/workspace-users/workspace-users.slice"
import accountOwnerSlice from "@store/account-owner/account-owner.slice"
import accountPersonalSlice from "@store/account-personal/account-personal.slice"
import accountsSlice from "@store/accounts/accounts.slice"
import businessSlice from "@store/business/business.slice"
import rolesSlice from "@store/roles/roles.slice"

const store = configureStore({
  reducer: {
    security: securitySlice.reducer,
    workspace: workspaceSlice.reducer,
    workspaceUsers: workspaceUsersSlice.reducer,
    accountOwner: accountOwnerSlice.reducer,
    accountPersonal: accountPersonalSlice.reducer,
    accounts: accountsSlice.reducer,
    business: businessSlice.reducer,
    roles: rolesSlice.reducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
