import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IAccountOwnerState } from "@interfaces/store/IAccountOwnerState"
import { IAccountOwnerModel } from "@interfaces/models/IAccountOwnerModel"

const accountOwnerState: IAccountOwnerState = {
  register: {
    loading: false,
    error: false,
    errors: [],
    data: {
      company_name: "",
      company_size: 0,
      owner_name: "",
      email: "",
      phone: "",
      website: "",
      name: "",
      terms_conditions: false,
    },
    output: {
      workspaces: {},
      account: {
        company_name: "",
        company_size: "",
        owner_name: "",
        email: "",
        phone: "",
        website: "",
        id: 0,
      },
      success: null,
    },
  },
  newPassword: {
    success: false,
    loading: false,
    error: false,
  },
  resendEmail: {
    loading: false,
  },
}

const accountOwnerSlice = createSlice({
  name: "AccountOwner",
  initialState: accountOwnerState,
  reducers: {
    setRegisterAccountData: (state, action: PayloadAction<IAccountOwnerModel>) => {
      state.register.data = action.payload
    },

    setRegisterAccountLoading: (state) => {
      state.register.loading = true
    },
    setRegisterAccountComplete: (state, action: PayloadAction<any>) => {
      const { success, data } = action.payload
      state.register.output = {
        success,
        account: data.account,
        workspaces: data.workspaces,
      }
      state.register.loading = false
      state.register.error = false
    },
    setRegisterAccountError: (state, { payload }: PayloadAction<any>) => {
      state.register.error = true
      state.register.loading = false
      state.register.errors = payload
    },

    setNewPasswordLoading: (state) => {
      state.newPassword.loading = true
    },
    setNewPasswordComplete: (state, action: PayloadAction<any>) => {
      const { success } = action.payload
      state.newPassword.success = success
      state.newPassword.loading = false
    },
    setNewPasswordError: (state) => {
      state.newPassword.error = true
      state.newPassword.loading = false
    },

    setResendEmailComplete: (state) => {
      state.resendEmail.loading = false
    },
    setResendEmailLoading: (state) => {
      state.resendEmail.loading = true
    },
  },
})

export default accountOwnerSlice
