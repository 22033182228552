import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IBusinessState } from "@interfaces/store/IBusinessState"

const initialState: IBusinessState = {
  data: {
    business: [],
    paginate: {
      currentPage: 1,
      total: 1,
      perPage: 10,
      path: "",
      firstPageUrl: "",
      lastPageUrl: "",
      nextPageUrl: "",
      prevPageUrl: "",
    },
  },

  loading: false,
  error: false,
}

const businessSlice = createSlice({
  name: "Business",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true
    },
    setComplete: (state) => {
      state.loading = false
    },
    setError: (state) => {
      state.loading = false
      state.error = true
    },
    setFetchBusinessComplete: (state, { payload }: PayloadAction<any>) => {
      const {
        data: business,
        current_page: currentPage,
        total,
        path,
        per_page: perPage,
        first_page_url: firstPageUrl,
        last_page_url: lastPageUrl,
        next_page_url: nextPageUrl,
        prev_page_url: prevPageUrl,
      } = payload

      state.data.business = business
      state.data.paginate = {
        currentPage: parseInt(currentPage),
        total: parseInt(total),
        perPage: parseInt(perPage),
        path,
        firstPageUrl,
        lastPageUrl,
        nextPageUrl,
        prevPageUrl,
      }
      state.loading = false
      state.error = false
    },
    setFetchBusinessCompletePerUser: (state, { payload }: PayloadAction<any>) => {
      state.data.business = payload
      state.loading = false
      state.error = false
    },
  },
})

export default businessSlice
