import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IWorkspaceState } from "@interfaces/store/IWorkspaceState"

const workspaceInitialState: IWorkspaceState = {
  data: {
    workspaces: [],
    paginate: {
      currentPage: 1,
      total: 1,
      perPage: 10,
      path: "",
      firstPageUrl: "",
      lastPageUrl: "",
      nextPageUrl: "",
      prevPageUrl: "",
    },
    launch: {
      loading: false,
      error: false,
    },
    workspace: {},
  },
  loading: false,
  error: false,
}

const workspaceSlice = createSlice({
  name: "Workspace",
  initialState: workspaceInitialState,
  reducers: {
    setFetchLoading: (state) => {
      state.loading = true
    },
    setFetchAllComplete: (state, action: PayloadAction<any>) => {
      const {
        data: {
          data: workspaces,
          current_page: currentPage,
          total,
          path,
          per_page: perPage,
          first_page_url: firstPageUrl,
          last_page_url: lastPageUrl,
          next_page_url: nextPageUrl,
          prev_page_url: prevPageUrl,
        },
      } = action.payload
      state.data.workspaces = workspaces
      state.data.paginate = {
        currentPage: parseInt(currentPage),
        total: parseInt(total),
        perPage: parseInt(perPage),
        path,
        firstPageUrl,
        lastPageUrl,
        nextPageUrl,
        prevPageUrl,
      }
      state.loading = false
      state.error = false
    },

    setFetchOwnComplete: (state, action: PayloadAction<any>) => {
      state.data.workspaces = action.payload
      state.loading = false
      state.error = false
    },

    setFetchDetailsComplete: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.data.workspace = action.payload
    },
    setFetchAllError: (state) => {
      state.error = true
    },

    setNewWorkspaceLoading: (state) => {
      state.loading = true
    },
    setNewWorkspaceComplete: (state) => {
      state.loading = false
    },
    setNewWorkspaceError: (state) => {
      state.error = true
      state.loading = false
    },

    setEditWorkspaceLoading: (state) => {
      state.loading = true
    },
    setEditWorkspaceComplete: (state, action: PayloadAction<any>) => {
      state.loading = false
    },
    setEditWorkspaceError: (state) => {
      state.loading = false
      state.error = true
    },

    setDeleteWorkspaceLoading: (state) => {
      state.loading = true
    },
    setDeleteWorkspaceComplete: (state, action: PayloadAction<any>) => {
      state.loading = false
    },
    setDeleteWorkspaceError: (state) => {
      state.loading = false
      state.error = true
    },

    setLaunchLoading: (state) => {
      state.data.launch.loading = true
      state.data.launch.error = false
    },
    setLaunchComplete: (state) => {
      state.data.launch.loading = false
      state.data.launch.error = false
    },
    setLaunchError: (state) => {
      state.data.launch.loading = false
      state.data.launch.error = true
    },

    setWorkspace: (state, { payload }: PayloadAction<any>) => {
      state.data.workspace = payload
    },
  },
})

export default workspaceSlice
